import React from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { Subscription } from 'rxjs'
import { productsState$ } from '../../generic/state'
import { ca } from '../../generic/supply/action-helpers'
import { IProduct } from '../api-firebase/types'
import { composeCaption } from './GalleryImage'
import { cssRule } from 'typestyle'
import { mediumFont } from '../../generic/theme'

export const openLightbox = ca<number>()

export default class GalleryLightbox extends React.PureComponent<{ productID: string }> {
	state: { index: number; isOpen: boolean; gallery: IProduct['gallery'] } = {
		index: 0,
		isOpen: false,
		gallery: [],
	}

	subs!: Subscription[]

	componentDidMount() {
		const productID = this.props.productID
		this.subs = [
			productsState$
				.view('cache')
				.view((cache) => cache[productID])
				.subscribe((product) => {
					this.setState({ gallery: product ? product.gallery : [] })
				}),
			openLightbox.$.subscribe((index) => {
				this.setState({ isOpen: true, index })
			}),
		]
	}

	componentWillUnmount() {
		this.subs.forEach((sub) => sub.unsubscribe())
	}

	getSrc(index: number) {
		const name = this.state.gallery[index].name
		return `https://ik.imagekit.io/sz/${name}`
	}

	getCaption(index: number) {
		return composeCaption(this.state.gallery[index]) || undefined
	}

	render() {
		const { index, isOpen, gallery } = this.state
		const prevIndex = (index + gallery.length - 1) % gallery.length
		const nextIndex = (index + 1) % gallery.length
		return (
			isOpen && (
				<Lightbox
					closeLabel="Close"
					mainSrc={this.getSrc(index)}
					nextSrc={this.getSrc(nextIndex)}
					prevSrc={this.getSrc(prevIndex)}
					onCloseRequest={() => this.setState({ isOpen: false })}
					onMovePrevRequest={() => this.setState({ index: prevIndex })}
					onMoveNextRequest={() => this.setState({ index: nextIndex })}
					animationDuration={120}
					imageCaption={this.getCaption(index)}
				/>
			)
		)
	}
}

cssRule('.ril__captionContent', mediumFont)
