const BASE_API =
	'https://firestore.googleapis.com/v1/projects/body-link/databases/(default)/documents'

export const callAPI = async <T>(path: string, init?: RequestInit): Promise<T> => {
	const res = await fetch(`${BASE_API}${path}`, init)
	return res.json()
}

const PROPS = [
	'arrayValue',
	'bytesValue',
	'booleanValue',
	'doubleValue',
	'geoPointValue',
	'integerValue',
	'mapValue',
	'nullValue',
	'referenceValue',
	'stringValue',
	'timestampValue',
]

export const parseFields = (value: any) => {
	const prop = Object.keys(value).find((k) => PROPS.includes(k))
	if (prop === 'doubleValue' || prop === 'integerValue') {
		value = Number(value[prop])
	} else if (prop === 'arrayValue') {
		value = ((value[prop] && value[prop].values) || []).map((v: any) => parseFields(v))
	} else if (prop === 'mapValue') {
		value = parseFields((value[prop] && value[prop].fields) || {})
	} else if (prop === 'timestampValue') {
		value = new Date(value[prop])
	} else if (prop === 'geoPointValue') {
		value = { latitude: 0, longitude: 0, ...value[prop] }
	} else if (prop) {
		value = value[prop]
	} else if (typeof value === 'object') {
		Object.keys(value).forEach((k) => (value[k] = parseFields(value[k])))
	}
	return value
}

export const fieldsToSelect = (fields: string[]) =>
	fields.map((field) => ({
		fieldPath: field,
	}))
