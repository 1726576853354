import React from 'react'

export type TIconName = keyof typeof icons

export const icons = {
	menu: {
		viewBox: '0 0 24 24',
		path: (
			<g>
				<rect x={3} y={7} width={18} height={2} rx={1} />
				<rect x={3} y={11} width={18} height={2} rx={1} />
				<rect x={3} y={15} width={18} height={2} rx={1} />
			</g>
		),
	},
	close: {
		viewBox: '0 0 24 24',
		path: (
			<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
		),
	},
	plus: {
		viewBox: '0 0 24 24',
		path: <path d="M13 3h-2v8H3v2h8v8h2v-8h8v-2h-8V3z" />,
	},
	appStore: {
		viewBox: '0 0 24 24',
		path: (
			<path d="M12.613 5.506v.008a.458.458 0 01-.457-.458A5.048 5.048 0 0116.185.008a.447.447 0 01.375.1.458.458 0 01.173.357 5.048 5.048 0 01-4.028 5.04h-.092zm8.397 9.649a.458.458 0 01.302.577C20.305 18.764 18.372 22 16.733 22a6.065 6.065 0 01-2.572-.54 3.75 3.75 0 00-1.548-.376 3.75 3.75 0 00-1.547.375 6.065 6.065 0 01-2.573.54C6.48 22 3 17.015 3 11.92c0-6.23 4.935-6.414 5.494-6.414a5.592 5.592 0 012.142.477 5.11 5.11 0 001.977.44 5.105 5.105 0 001.978-.44 5.6 5.6 0 012.142-.477 5.491 5.491 0 013.965 1.75.457.457 0 01-.11.706 4.088 4.088 0 00-2.023 3.528 3.941 3.941 0 002.444 3.665z" />
		),
	},
	googlePlay: {
		viewBox: '0 0 24 24',
		path: (
			<path d="M13.909 11.717a.407.407 0 010 .566l-9.157 9.58a.44.44 0 01-.677-.045A.413.413 0 014 21.58V2.42a.413.413 0 01.275-.391.444.444 0 01.477.107l9.156 9.58v.001zm1.21 1.266l2.124 2.241a.41.41 0 01.043.516.415.415 0 01-.138.125 988.952 988.952 0 01-9.287 5.258.444.444 0 01-.553-.099.406.406 0 01.018-.543l7.153-7.497a.458.458 0 01.64 0zm7.454-1.716l-.035-.009a.83.83 0 01.462.738c0 .309-.177.593-.462.737l-3.852 2.225a.445.445 0 01-.536-.075l-2.488-2.6a.407.407 0 010-.566l2.488-2.6a.442.442 0 01.536-.075l3.887 2.225zm-8.086-.25l-7.152-7.49a.407.407 0 01-.018-.542.44.44 0 01.553-.099c2.755 1.55 6.194 3.491 9.295 5.257a.414.414 0 01.206.298.405.405 0 01-.11.343l-2.135 2.233a.437.437 0 01-.494.102.437.437 0 01-.145-.102z" />
		),
	},
	website: {
		viewBox: '0 0 24 24',
		path: (
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 6a4 4 0 014-4h10a4 4 0 014 4v1H3V6zm7 3h11v9a4 4 0 01-4 4h-7V9zM8 9H3v9a4 4 0 004 4h1V9z"
			/>
		),
	},
	telegram: {
		viewBox: '0 0 24 24',
		path: (
			<path d="M19.55 3c-.24.01-.47.07-.7.16l-2.78 1.16-5.53 2.34-7.88 3.32.05-.02s-.27.09-.55.28c-.18.11-.33.25-.44.42-.14.2-.25.52-.21.84.07.54.42.86.67 1.04.25.18.5.27.5.27l3.67 1.23 1.34 4.38c.14.43.27.7.43.9.08.11.17.2.29.27l.18.08-.04-.01.03.01.09.02c.58.18 1.04-.18 1.04-.18l.03-.03 2.16-1.96 3.63 2.78.08.03c.75.33 1.52.15 1.92-.18.41-.32.57-.74.57-.74l.03-.07 2.8-14.35c.08-.35.1-.68 0-1a1.36 1.36 0 0 0-1.38-1zm-.07 1.53l-.02.14-2.77 14.2c-.01.02-.03.07-.09.11-.06.05-.1.08-.35-.02l-4.43-3.4L9.14 18l.56-3.59 7.25-6.75c.3-.28.2-.34.2-.34.02-.34-.46-.1-.46-.1l-9.13 5.66v-.01l-4.38-1.48h-.01l.02-.01.03-.01.02-.01 7.88-3.32 5.54-2.33 2.74-1.15c.06-.02.03-.02.08-.02z" />
		),
	},
	whatsapp: {
		viewBox: '0 0 24 24',
		path: (
			<path d="M3.04 21l1.34-4.88A9.43 9.43 0 0119.2 4.76 9.43 9.43 0 018.04 19.7l-5 1.31zm5.23-3.01a7.84 7.84 0 104.27-14.4 7.84 7.84 0 00-6.45 12.28l-.79 2.9 2.97-.78zm9.01-4.33c-.06-.1-.22-.16-.45-.27-.24-.12-1.4-.7-1.6-.77-.22-.08-.38-.12-.54.12-.16.23-.6.76-.74.92-.14.16-.28.18-.51.06-.24-.12-1-.37-1.9-1.17a7.08 7.08 0 01-1.3-1.63c-.14-.23-.02-.36.1-.48.1-.1.23-.27.35-.41.12-.14.16-.24.24-.4.08-.15.04-.29-.02-.4l-.73-1.75c-.19-.46-.38-.4-.53-.4l-.45-.01a.86.86 0 00-.62.3c-.22.23-.83.8-.83 1.95 0 1.16.85 2.28.96 2.44.12.15 1.66 2.53 4.02 3.55.56.24 1 .39 1.34.5.57.17 1.08.15 1.48.09.46-.07 1.4-.57 1.6-1.12.19-.55.19-1.02.13-1.12z" />
		),
	},
	twitter: {
		viewBox: '0 0 24 24',
		path: (
			<path d="M22 6.02c-.79.35-1.62.57-2.48.67a4.31 4.31 0 0 0 1.9-2.37c-.85.5-1.77.85-2.74 1.04a4.31 4.31 0 0 0-7.34 3.91A12.27 12.27 0 0 1 2.46 4.8a4.27 4.27 0 0 0 1.34 5.73 4.3 4.3 0 0 1-1.96-.54v.06a4.28 4.28 0 0 0 3.46 4.2c-.63.18-1.3.2-1.95.08a4.3 4.3 0 0 0 4.03 2.98A8.66 8.66 0 0 1 1 19.07 12.23 12.23 0 0 0 7.6 21c7.93 0 12.26-6.54 12.26-12.2l-.01-.56A8.74 8.74 0 0 0 22 6.02z" />
		),
	},
	facebook: {
		viewBox: '0 0 24 24',
		path: (
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.53 5.33h-1.87c-1.53 0-1.87.67-1.87 1.67v2.33h3.57l-.51 3.5h-3.06V22H9.06v-9.17H6v-3.5h3.06V6.67c0-3 1.86-4.67 4.75-4.67 1.36 0 2.55.17 2.72.17v3.16z"
			/>
		),
	},
} as const
