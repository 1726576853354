import { fromEvent } from 'rxjs'
import { auditTime, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators'
import { graduations, TGraduationName } from './theme'

export const vw$ = fromEvent(window, 'resize').pipe(
	auditTime(100),
	map(() => window.innerWidth),
	startWith(window.innerWidth),
	distinctUntilChanged(),
	shareReplay(1)
)

export const graduations$ = vw$.pipe(
	map((vw) => {
		const res: { [key in TGraduationName]: boolean } = {} as any
		let graduation: TGraduationName
		for (graduation in graduations) {
			const [min, max] = graduations[graduation]
			res[graduation] = vw >= min && vw <= max
		}
		return res
	}),
	distinctUntilChanged(),
	shareReplay(1)
)
