import { combineLatest, interval, of } from 'rxjs'
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators'
import { productsState$ } from '../../../generic/state'
import { timeAgo } from '../../../generic/supply/formatters'

const sortedProductCache$ = productsState$
	.view('cache')
	.view((cache) => Object.values(cache).sort((a, b) => Number(b.created) - Number(a.created)))

export const latestArrivalsProducts$ = sortedProductCache$.view((products) =>
	products.slice(0, LATEST_ARRIVALS_LIMIT)
)

export const updatedTime$ = combineLatest(
	latestArrivalsProducts$.view('length').view(Boolean),
	productsState$.view('latestArrivalsLastUpdate')
).pipe(
	switchMap(([isAnyProduct, updated]) =>
		isAnyProduct
			? interval(1000).pipe(
					startWith(0),
					map(timeAgo.bind(null, updated))
			  )
			: of(null)
	),
	shareReplay(1)
)

export const LATEST_ARRIVALS_LIMIT = 20
