import React from 'react'
import { classes, style } from 'typestyle'
import { tv } from '../../theme'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {}

export const ModalOverlay: React.FC<IProps> = ({ children, className, ...rest }) => {
	return (
		<div className={classes($container, className)} {...rest}>
			{children}
		</div>
	)
}

const $container = style({
	position: 'fixed',
	left: 0,
	top: 0,
	width: '100vw',
	height: '100vh',
	backgroundColor: tv('blue500'),
	opacity: 0.5,
})
