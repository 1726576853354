import React from 'react'
import { style } from 'typestyle'
import { ReactiveBind } from '../../../generic/components/Reactive/Bind'
import { Stack } from '../../../generic/components/Stack'
import { TextLoading } from '../../../generic/components/TextLoading'
import { Graduations } from '../../../generic/components/Utils/Graduations'
import { mediaGrad } from '../../../generic/supply/style-helpers'
import { headerFont, smallFont, tv } from '../../../generic/theme'
import { ProductList } from '../../product/ProductList'
import { LinkButton } from '../../router/Link'
import { attachEpicLatestArrivals } from './epic'
import { latestArrivalsProducts$, updatedTime$ } from './state'

export const LatestArrivals = attachEpicLatestArrivals(() => {
	return (
		<div className={$container}>
			<div className={$header}>
				<Graduations>
					{({ mobileM }) => (
						<Stack spacing={mobileM ? 1 : 2} isInline={!mobileM} isCentered={!mobileM}>
							<h2 className={$title}>Latest arrivals</h2>
							<span className={$time}>
								<ReactiveBind $={updatedTime$}>
									{(updatedTime) => {
										return updatedTime || <TextLoading type="time" />
									}}
								</ReactiveBind>
							</span>
						</Stack>
					)}
				</Graduations>
				<LinkButton name="products" size="sm" className={$showAll}>
					Show All
				</LinkButton>
			</div>
			<div className={$productList}>
				<ReactiveBind $={latestArrivalsProducts$}>
					{(products) => <ProductList products={products} />}
				</ReactiveBind>
			</div>
		</div>
	)
})

const $container = style(
	{
		marginTop: '8rem',
		maxWidth: '470px',
	},
	mediaGrad('sm', {
		marginTop: '6rem',
	})
)

const $header = style({
	display: 'flex',
	alignItems: 'center',
})

const $title = style(headerFont, {
	margin: 0,
	color: tv('dark500'),
})

const $time = style(smallFont, {
	color: tv('dark400'),
})

const $showAll = style({
	marginLeft: 'auto',
})

const $productList = style({
	marginTop: '2rem',
})
