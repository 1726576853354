import React from 'react'
import { style } from 'typestyle'
import { Hero } from './Hero'
import { mediaGrad } from '../../generic/supply/style-helpers'
import { LatestArrivals } from './LatestArrivals/LatestArrivals'

export const PageMain: React.FC = () => {
	return (
		<div className={$container}>
			<Hero />
			<LatestArrivals />
		</div>
	)
}

const $container = style(
	{
		padding: '1rem 2rem 2rem',
		flex: 'none',
		overflowX: 'hidden',
	},
	mediaGrad('mdPlus', {
		padding: '5rem 6rem 6rem',
	})
)
