import React from 'react'
import { Subscription } from 'rxjs'

export const createAttachableEpic = (initSubs: () => Subscription[]) => {
	return (comp: React.FunctionComponent | React.ComponentClass) => {
		return class AttachedEpic extends React.Component {
			subs: Subscription[] = []
			componentDidMount() {
				this.subs = initSubs()
			}
			componentWillUnmount() {
				this.subs.forEach((sub) => sub.unsubscribe())
			}
			render() {
				return React.createElement(comp)
			}
		}
	}
}
