import { Atom } from '@grammarly/focal'
import { IProduct } from '../modules/api-firebase/types'
import { IRouterState } from '../modules/router/types'
import { routerGetState } from '../modules/router/router'

export interface IState {
	router: IRouterState
	products: {
		cache: { [productID: string]: IProduct }
		pagination: {
			[pageSlug: string]: {
				hasMore: boolean
				offset: number
			}
		}
		latestArrivalsLastUpdate: Date
	}
}

const date = new Date()
export const defaultState: IState = {
	router: routerGetState(),
	products: {
		cache: {},
		pagination: {},
		latestArrivalsLastUpdate: date,
	},
}

export const appState$ = Atom.create<IState>(defaultState)
export const routerState$ = appState$.lens('router')
export const productsState$ = appState$.lens('products')
