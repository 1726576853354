import { rem } from 'csx'
import React from 'react'
import { classes, style } from 'typestyle'
import { applyVariables, tv, shadowModal, shadowHeader } from '../theme'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
	color?: keyof typeof $colors
	shadow?: keyof typeof $shadows
	isBordered?: boolean
	isRounded?: boolean
}

export const Box: React.FC<IProps> = ({
	color = 'white',
	shadow,
	isBordered = false,
	isRounded = false,
	children,
	className,
	...rest
}) => {
	const $color = $colors[color]
	const $shadow = shadow && $shadows[shadow]
	return (
		<div
			className={classes(
				$color,
				$shadow,
				isBordered && $isBordered,
				isRounded && $isRounded,
				$container,
				className
			)}
			{...rest}
		>
			{children}
		</div>
	)
}

const $container = style({
	position: 'relative',
	backgroundColor: 'var(--color-box-bg)',
})

const $isBordered = style({
	border: '1px solid var(--color-box-border)',
})

const $isRounded = style({
	borderRadius: rem(1),
})

const $colors = {
	white: style(
		applyVariables({
			'color-box-bg': tv('white'),
			'color-box-border': tv('blue200'),
		})
	),
}

const $shadows = {
	header: style(shadowHeader),
	modal: style(shadowModal),
}
