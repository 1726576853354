import { createContext, useContext } from 'react'

export interface IModalContext {
	isOpen: boolean
	onOverlayClick?: (e: React.MouseEvent) => void
	onEsc?: (e: KeyboardEvent) => void
}

export const ModalContext = createContext<IModalContext>({
	isOpen: false,
})

export const useModalContext = () => useContext(ModalContext)
