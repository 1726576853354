import React from 'react'
import { style } from 'typestyle'
import { tv, heroFont, mediumFont } from '../../generic/theme'
import { ImageLady } from './ImageLady'
import { mediaGrad } from '../../generic/supply/style-helpers'

export const Hero: React.FC = () => {
	return (
		<div className={$container}>
			<div className={$text}>
				<h1 className={$title}>
					Share&nbsp;and&nbsp;discover
					<br />
					wellness&nbsp;products
				</h1>
				<p className={$tagLine}>
					Body Link is the place for health-conscious enthusiasts to share and discover apps,
					wearables, devices, services, sources — all for wellness development.
				</p>
			</div>
			<div className={$image}>
				<ImageLady width="46em" />
			</div>
		</div>
	)
}

const $container = style(
	{
		display: 'flex',
	},
	mediaGrad('sm', {
		flexDirection: 'column',
	})
)

const $text = style({
	flex: '0 1 0',
})

const $title = style(
	heroFont,
	{
		color: tv('dark400'),
	},
	mediaGrad('mobileL', {
		fontSize: '11.4vw',
	})
)

const $tagLine = style(mediumFont, {
	maxWidth: '470px',
	color: tv('dark500'),
})

const $image = style(
	{
		fontSize: '1rem',
	},
	mediaGrad('sm', {
		marginTop: '-5em',
	}),
	mediaGrad('mobileL', {
		fontSize: '2vw',
	})
)
