import { Lens, Atom } from '@grammarly/focal'
import { appState$, IState } from '../../generic/state'
import { IProduct } from '../api-firebase/types'

interface IProductState {
	product?: IProduct
	slug?: string
}

export const productState$ = appState$.lens(
	Lens.create<IState, IProductState>(
		({ router, products }) => {
			const slug = getProductSlug(router)
			return {
				slug,
				product: slug ? products.cache[slug] : undefined,
			}
		},
		({ product }, appState) => {
			const stateProducts = appState.products
			if (product) {
				return {
					...appState,
					products: {
						...stateProducts,
						cache: { ...stateProducts.cache, [product.id]: product },
					},
				}
			}
			return appState
		}
	)
)

export const isFound$ = Atom.create(true)

const getProductSlug = (router: IState['router']) =>
	router.params['products.product'] && router.params['products.product'].productSlug
