import React from 'react'
import { style } from 'typestyle'
import { Box } from '../../generic/components/Box'
import { ButtonLink } from '../../generic/components/Button'
import { Stack } from '../../generic/components/Stack'
import { mapToQuery } from '../../generic/supply/formatters'
import { mediaGrad } from '../../generic/supply/style-helpers'
import { mediumFont, tv } from '../../generic/theme'
import { IProduct } from '../api-firebase/types'

export const ShareButtons: React.FC<{ product: IProduct }> = ({ product: { name, tagline } }) => {
	const getURL = buildURLGetter(`${name} — ${tagline}`)
	return (
		<Box color="white" isBordered className={$container}>
			<Stack spacing={2}>
				<div className={$appeal}>
					Share {name} with someone who might&nbsp;need&nbsp;it&nbsp;
					<span role="img" aria-label="Share your love">
						💕
					</span>
				</div>
				<Stack spacing={1} isInline isWrap>
					<ButtonLink
						href={getURL('telegram')}
						leftIcon="telegram"
						ghostColor="#0088cc"
						target="_blank"
					>
						Telegram
					</ButtonLink>
					<ButtonLink
						href={getURL('whatsapp')}
						leftIcon="whatsapp"
						ghostColor="#128c7e"
						target="_blank"
					>
						WhatsApp
					</ButtonLink>
					<ButtonLink
						href={getURL('twitter')}
						leftIcon="twitter"
						ghostColor="#1da1f2"
						target="_blank"
					>
						Tweet
					</ButtonLink>
					<ButtonLink
						href={getURL('facebook')}
						leftIcon="facebook"
						ghostColor="#3b5998"
						target="_blank"
					>
						Share
					</ButtonLink>
				</Stack>
			</Stack>
		</Box>
	)
}

const $container = style(
	{
		padding: '5rem',
	},
	mediaGrad('mobileL', {
		padding: '5rem 2rem',
	})
)

const $appeal = style(mediumFont, {
	color: tv('dark800'),
})

const buildURLGetter = (text: string) => (site: 'facebook' | 'telegram' | 'twitter' | 'whatsapp') =>
	`https://simpleshare.io/go${mapToQuery({
		site,
		text,
		url: String(document.location),
		ga: 'UA-129342832-3',
	})}`
