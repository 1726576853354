import React from 'react'
import { style } from 'typestyle'
import { Box } from '../../generic/components/Box'
import { ContentLoading } from '../../generic/components/ContentLoading'
import { mediaGrad } from '../../generic/supply/style-helpers'
import { applyVariables, mediumFont, mediumFontBold, tv } from '../../generic/theme'
import { IProduct } from '../api-firebase/types'
import { Thumbnail } from './Thumbnail'

export const ProductHead: React.FC<{ product?: IProduct }> = ({ product }) => {
	return <div className={$container}>{product ? <Loaded product={product} /> : <Loading />}</div>
}

const Loading: React.FC = () => (
	<Box color="white" isBordered className={$main}>
		<ContentLoading
			style={{ height: '80px' }}
			title="Product info is loading"
			height={80}
			width={254}
		>
			<rect x="0" y="0" rx="0" ry="0" width="80" height="80" />
			<rect x="92" y="0" rx="0" ry="0" width="110" height="20" />
			<rect x="92" y="28" rx="0" ry="0" width="150" height="16" />
			<rect x="92" y="52" rx="4" ry="4" width="75" height="28" />
			<rect x="177" y="52" rx="4" ry="4" width="75" height="28" />
		</ContentLoading>
	</Box>
)

const Loaded: React.FC<{ product: IProduct }> = ({ product }) => {
	const { name, thumbnail, tagline } = product
	return (
		<Box color="white" isBordered className={$main}>
			<Thumbnail alt={name} thumbnail={thumbnail} />
			<div className={$content}>
				<h1 className={$name}>{name}</h1>
				<div className={$tagline}>{tagline}</div>
			</div>
		</Box>
	)
}

const $container = style(
	applyVariables({
		'space-card': '20px',
	}),
	{
		position: 'relative',
	},
	mediaGrad(
		'mobileM',
		applyVariables({
			'space-card': '2rem',
		})
	)
)

const $main = style({
	display: 'flex',
	padding: 'var(--space-card)',
})

const $content = style({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: '12px',
})

const $name = style(mediumFontBold, {
	flex: 'none',
	margin: 0,
	color: tv('dark800'),
})

const $tagline = style(mediumFont, {
	flexGrow: 1,
	marginTop: '.5rem',
	color: tv('dark500'),
})
