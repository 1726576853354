import React from 'react'
import { style } from 'typestyle'
import { Box } from '../../generic/components/Box'
import { ContentLoading } from '../../generic/components/ContentLoading'
import { Stack } from '../../generic/components/Stack'
import { stringToSlug } from '../../generic/supply/formatters'
import { mediaGrad } from '../../generic/supply/style-helpers'
import { applyVariables, linkFontFancy, mediumFontBold, smallFont, tv } from '../../generic/theme'
import { IProduct } from '../api-firebase/types'
import { Link } from '../router/Link'
import { Thumbnail } from './Thumbnail'

export const ProductCard: React.FC<{ product?: IProduct }> = ({ product }) => {
	return <div className={$container}>{product ? <Loaded product={product} /> : <Loading />}</div>
}

const Loading: React.FC = () => (
	<Box color="white" isBordered className={$main}>
		<ContentLoading
			style={{ height: '80px' }}
			title="Product card is loading"
			height={80}
			width={254}
		>
			<rect x="0" y="0" rx="0" ry="0" width="80" height="80" />
			<rect x="92" y="0" rx="0" ry="0" width="110" height="20" />
			<rect x="92" y="28" rx="0" ry="0" width="162" height="16" />
			<rect x="92" y="64" rx="0" ry="0" width="50" height="16" />
		</ContentLoading>
	</Box>
)

const Loaded: React.FC<{ product: IProduct }> = ({ product }) => {
	const { id, name, thumbnail, tagline, topics } = product
	return (
		<>
			<Link name="products.product" props={{ productSlug: id }} className={$productLink}>
				<Box color="white" isBordered className={$main}>
					<Thumbnail alt={name} thumbnail={thumbnail} />
					<div className={$content}>
						<div className={$name}>{name}</div>
						<div className={$tagline}>{tagline}</div>
					</div>
				</Box>
			</Link>
			<div className={$meta}>
				<Stack spacing={1} isInline>
					{topics.map((topic) => (
						<Link
							key={topic}
							name="topics.topic"
							props={{ topicSlug: stringToSlug(topic) }}
							className={$topicLink}
						>
							{topic}
						</Link>
					))}
				</Stack>
			</div>
		</>
	)
}

const $container = style(
	applyVariables({
		'space-card': '20px',
	}),
	{
		overflow: 'hidden',
		position: 'relative',
	},
	mediaGrad(
		'mobileM',
		applyVariables({
			'space-card': '2rem',
		})
	)
)

const $main = style({
	display: 'flex',
	padding: 'var(--space-card)',
})

const $meta = style({
	position: 'absolute',
	left: 'calc(var(--space-card) + 92px)',
	bottom: 'var(--space-card)',
})

const $content = style({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: '12px',
})

const $name = style(mediumFontBold, {
	flex: 'none',
	color: tv('dark800'),
})

const $tagline = style(smallFont, {
	flexGrow: 1,
	margin: '1rem 0 3rem',
	color: tv('dark500'),
})

const $productLink = style({
	textDecoration: 'none',
})

const $topicLink = style(smallFont, linkFontFancy({ colorLink: tv('link500') }))
