import { throttleTime } from 'rxjs/operators'
import { IState, productsState$ } from '../../../generic/state'
import { ca } from '../../../generic/supply/action-helpers'
import { createAttachableEpic } from '../../../generic/supply/epic-helpers'
import { createFig } from '../../../generic/supply/fig-helpers'
import { getProductCards } from '../../api-firebase/getProductCards'
import { IProduct } from '../../api-firebase/types'
import { LATEST_ARRIVALS_LIMIT } from './state'

const loadLatestArrivals = ca()

const figLatestArrivals = createFig()

loadLatestArrivals.$.pipe(throttleTime(10 * 1000)).subscribe(() => {
	figLatestArrivals.run<IProduct[]>({
		task: getProductCards.bind(null, { limit: LATEST_ARRIVALS_LIMIT }),
		attempts: 3,
		onFinish: (products) => {
			if (!(products instanceof Error)) {
				productsState$.modify((stateProducts) => {
					return {
						...stateProducts,
						cache: products.length
							? products.reduce<IState['products']['cache']>(
									(cache, product) => {
										cache[product.id] = product
										return cache
									},
									{ ...stateProducts.cache }
							  )
							: stateProducts.cache,
						latestArrivalsLastUpdate: new Date(),
					}
				})
			}
		},
	})
})

export const attachEpicLatestArrivals = createAttachableEpic(() => {
	loadLatestArrivals()
	return []
})
