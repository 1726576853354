import { em, percent, px } from 'csx'
import { NestedCSSProperties } from 'typestyle/lib/types'

export const lightTheme = {
	white: '#fff',
	link400: '#74B2ED',
	link500: '#3991E5',
	pink400: '#FFB1BD',
	pink500: '#FF90A0',
	pink700: '#995660',
	dark400: '#757575',
	dark500: '#3A3A3A',
	dark800: '#1A1A1A',
	blue100: '#F5F7FA',
	blue200: '#E5EBF1',
	blue400: '#B7C6D9',
	blue500: '#98ADC8',
	blue700: '#5B6878',
}

export const breakpoints = {
	sm: 600,
	md: 960,
	lg: 1360,
} as const

export type TGraduationName = keyof typeof graduations
export const graduations = {
	// Base
	sm: [0, breakpoints.sm],
	md: [breakpoints.sm + 1, breakpoints.md],
	lg: [breakpoints.md + 1, breakpoints.lg],
	// smPlus -- it's from 0 to Infinity, it's CSS default
	mdPlus: [breakpoints.sm + 1, Infinity],
	lgPlus: [breakpoints.md + 1, Infinity],
	hdPlus: [breakpoints.lg + 1, Infinity],

	// Custom
	mobileL: [0, 425],
	mobileM: [0, 375],
	mobileS: [0, 320],
} as const

const fontWeights = {
	normal: 300,
	bold: 500,
}

const fontDefaults: NestedCSSProperties = {
	fontFamily: 'Roboto, "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif',
	fontStyle: 'normal',
	fontWeight: fontWeights.normal,
}

export const heroFont: NestedCSSProperties = {
	...fontDefaults,
	fontSize: px(44),
	lineHeight: em(47 / 44),
	letterSpacing: em(-0.025),
}

export const headerFont: NestedCSSProperties = {
	...fontDefaults,
	fontSize: px(24),
	lineHeight: em(28 / 24),
	letterSpacing: em(-0.01),
}

export const mediumFont: NestedCSSProperties = {
	...fontDefaults,
	fontSize: px(17),
	lineHeight: em(20 / 17),
}

export const mediumFontBold: NestedCSSProperties = {
	...mediumFont,
	fontWeight: fontWeights.bold,
}

export const smallFont: NestedCSSProperties = {
	...fontDefaults,
	fontSize: px(14),
	lineHeight: em(16 / 14),
}

export const smallFontBold: NestedCSSProperties = {
	...smallFont,
	fontWeight: fontWeights.bold,
}

type ILinkFontProps = Record<'colorLink', string>
export const linkFont: (props?: Partial<ILinkFontProps>) => NestedCSSProperties = (props) => {
	const variables: ILinkFontProps = { colorLink: tv('link500'), ...props }
	return {
		...applyVariables(variables),
		textDecoration: 'none',
		color: 'var(--color-link)',
		$nest: {
			'&:hover': {
				textDecoration: 'underline',
			},
		},
	}
}

type ILinkFontFancyProps = Record<'colorLink', string>
export const linkFontFancy: (props?: Partial<ILinkFontFancyProps>) => NestedCSSProperties = (
	props
) => {
	const variables: ILinkFontFancyProps = { colorLink: tv('link500'), ...props }
	return {
		...applyVariables(variables),
		display: 'inline-block',
		position: 'relative',
		textDecoration: 'none',
		color: 'var(--color-link)',
		$nest: {
			'&:after': {
				content: "''",
				position: 'absolute',
				bottom: 0,
				left: 0,
				right: 0,
				width: percent(100),
				height: px(3),
				transform: 'scaleX(0)',
				transformOrigin: 'bottom right',
				backgroundColor: 'var(--color-link)',
				transition: 'transform 0.3s',
			},
			'&:hover:after': {
				transform: 'scaleX(1)',
				transformOrigin: 'bottom left',
			},
		},
	}
}

export const shadowHeader: NestedCSSProperties = {
	boxShadow: ['0 -3px 20px rgba(0, 0, 0, 0.34)', '0 1px 3px rgba(0, 0, 0, 0.2)'].join(', '),
}

export const shadowModal: NestedCSSProperties = {
	boxShadow: [
		'0 20px 70px rgba(0, 0, 0, 0.3)',
		'0 10px 30px rgba(0, 0, 0, 0.2)',
		'0 0 6px rgba(0, 0, 0, 0.4)',
	].join(', '),
}

export const shadowStairs: NestedCSSProperties = {
	boxShadow: ['0 0 0 0.5rem rgba(255,255,255,0.6)', '0 0 0 1rem rgba(255,255,255,0.4)'].join(', '),
}

export const tv = (key: keyof typeof lightTheme) => `var(--${key})`

export const applyVariables = (variables: { [key: string]: string }): any => {
	return Object.entries(variables).reduce<any>((acc, [name, val]) => {
		acc[`--${name}`] = val
		return acc
	}, {})
}
