import { createAttachableEpic } from '../../generic/supply/epic-helpers'
import { createFig } from '../../generic/supply/fig-helpers'
import { getProduct } from '../api-firebase/getProduct'
import { IProduct } from '../api-firebase/types'
import { productState$, isFound$ } from './state'

export const attachEpicPageProduct = createAttachableEpic(() => {
	const figGetProduct = createFig()
	return [
		productState$.view('slug').subscribe((slug) => {
			// slug can be undefined in case when Attachable component gonna unmount
			// coz Shell switch tha page but productState change coz router state
			if (slug) {
				isFound$.set(true)
				figGetProduct.run<IProduct | null>({
					task: getProduct.bind(null, slug),
					attempts: 3,
					onFinish: (product) => {
						if (!product || product instanceof Error) {
							isFound$.set(false)
						} else {
							productState$.set({ product })
						}
					},
				})
			}
		}),
	]
})
