import React from 'react'
import { cssRule } from 'typestyle'
import { ReactiveBind } from '../../generic/components/Reactive/Bind'
import { Header } from '../header/Header'
import { attachEpicShell } from './epic'
import { SEO } from './SEO'
import { page$ } from './state'

export const Shell = attachEpicShell(() => {
	return (
		<>
			<SEO />
			<Header />
			<ReactiveBind $={page$} />
		</>
	)
})

cssRule('body', {
	display: 'flex',
	contain: 'strict',
	overflow: 'hidden',
	overscrollBehavior: 'none',
})

cssRule('#root', {
	flexGrow: 1,
	display: 'flex',
	flexDirection: 'column',
	overflowY: 'auto',
})
