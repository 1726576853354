import React from 'react'

export const Logo: React.FC = () => (
	<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M27.6998 12L43.1941 42.9886H12.2054L27.6998 12Z"
			fill="url(#paint0_linear)"
		/>
		<path
			d="M42.7292 42.9886C51.2865 42.9886 58.2236 36.0516 58.2236 27.4943C58.2236 18.937 51.2865 12 42.7292 12C34.172 12 27.2349 18.937 27.2349 27.4943C27.2349 36.0516 34.172 42.9886 42.7292 42.9886Z"
			fill="url(#paint1_radial)"
		/>
		<path
			d="M37.2215 57.2434V51.0516H38.1157V56.4705H40.9195L40.8207 57.2434H37.2215Z"
			fill="#9B9B9B"
		/>
		<path
			d="M44.1512 50.2428C44.331 50.2428 44.4747 50.2982 44.5826 50.409C44.6934 50.5199 44.7488 50.6547 44.7488 50.8134C44.7488 50.9782 44.6934 51.1175 44.5826 51.2313C44.4747 51.3421 44.331 51.3976 44.1512 51.3976C43.9745 51.3976 43.8307 51.3421 43.7199 51.2313C43.612 51.1175 43.5581 50.9782 43.5581 50.8134C43.5581 50.6547 43.612 50.5199 43.7199 50.409C43.8307 50.2982 43.9745 50.2428 44.1512 50.2428ZM44.7938 52.5074V56.5874H46.1103V57.2434H42.4887V56.5874H43.931V53.1634H42.5336V52.5074H44.7938Z"
			fill="#9B9B9B"
		/>
		<path
			d="M47.7019 57.2434V52.5074H48.4434L48.5063 53.1455C48.701 52.9028 48.9376 52.7171 49.2162 52.5883C49.4978 52.4595 49.7779 52.3951 50.0565 52.3951C50.5118 52.3951 50.8473 52.5194 51.063 52.768C51.2787 53.0137 51.3865 53.3567 51.3865 53.797V57.2434H50.5238V54.3272C50.5238 54.0277 50.5058 53.785 50.4699 53.5993C50.4369 53.4136 50.3665 53.2788 50.2587 53.1949C50.1508 53.108 49.9876 53.0646 49.7689 53.0646C49.5113 53.0646 49.2776 53.144 49.0679 53.3027C48.8582 53.4615 48.6905 53.6322 48.5647 53.815V57.2434H47.7019Z"
			fill="#9B9B9B"
		/>
		<path
			d="M53.9936 50.4989V57.2434H53.1309V50.6022L53.9936 50.4989ZM57.1479 52.5074L55.108 54.6328L57.3726 57.2434H56.2807L54.0745 54.6552L56.083 52.5074H57.1479Z"
			fill="#9B9B9B"
		/>
		<path
			d="M15.0845 54.3064C15.0845 54.6479 15.0171 54.9325 14.8823 55.1602C14.7504 55.3879 14.5707 55.5691 14.3431 55.7039C14.1184 55.8387 13.8653 55.936 13.5837 55.996C13.3051 56.0529 13.019 56.0813 12.7254 56.0813H11V49.8895H12.5951C12.8737 49.8895 13.1448 49.9149 13.4084 49.9659C13.672 50.0138 13.9102 50.0977 14.1229 50.2175C14.3356 50.3343 14.5033 50.4946 14.6261 50.6983C14.7519 50.899 14.8149 51.1506 14.8149 51.4532C14.8149 51.7138 14.7594 51.937 14.6486 52.1227C14.5408 52.3054 14.4 52.4537 14.2262 52.5675C14.0525 52.6784 13.8668 52.7562 13.6691 52.8012C13.8937 52.8401 14.1139 52.912 14.3296 53.0169C14.5482 53.1187 14.728 53.273 14.8688 53.4797C15.0126 53.6834 15.0845 53.959 15.0845 54.3064ZM13.9072 51.5116C13.9072 51.1761 13.8024 50.9379 13.5927 50.7971C13.386 50.6533 13.0999 50.5815 12.7344 50.5815H11.8897V52.4956H12.8063C13.1628 52.4956 13.4354 52.4192 13.6241 52.2665C13.8128 52.1107 13.9072 51.8591 13.9072 51.5116ZM14.1498 54.3199C14.1498 54.0234 14.0914 53.7942 13.9746 53.6324C13.8608 53.4677 13.7065 53.3539 13.5118 53.2909C13.3201 53.228 13.1074 53.1966 12.8737 53.1966H11.8897V55.3669H12.8333C13.034 55.3669 13.2347 55.3414 13.4354 55.2905C13.6391 55.2366 13.8083 55.1347 13.9431 54.9849C14.0809 54.8352 14.1498 54.6135 14.1498 54.3199Z"
			fill="#202020"
		/>
		<path
			d="M18.2847 51.233C18.9617 51.233 19.4754 51.4577 19.8259 51.907C20.1794 52.3563 20.3561 52.9569 20.3561 53.7088C20.3561 54.1971 20.2753 54.6285 20.1135 55.0029C19.9547 55.3774 19.7211 55.6694 19.4125 55.8791C19.104 56.0888 18.7251 56.1937 18.2757 56.1937C17.5987 56.1937 17.082 55.969 16.7255 55.5197C16.372 55.0703 16.1953 54.4697 16.1953 53.7178C16.1953 53.2265 16.2747 52.7952 16.4334 52.4237C16.5952 52.0493 16.8304 51.7572 17.1389 51.5475C17.4504 51.3378 17.8324 51.233 18.2847 51.233ZM18.2847 51.9205C17.8953 51.9205 17.6017 52.0673 17.404 52.3608C17.2063 52.6514 17.1074 53.1037 17.1074 53.7178C17.1074 54.3259 17.2048 54.7768 17.3995 55.0703C17.5942 55.3609 17.8863 55.5062 18.2757 55.5062C18.6651 55.5062 18.9572 55.3609 19.1519 55.0703C19.3466 54.7768 19.444 54.3229 19.444 53.7088C19.444 53.0977 19.3466 52.6469 19.1519 52.3563C18.9572 52.0658 18.6681 51.9205 18.2847 51.9205Z"
			fill="#202020"
		/>
		<path
			d="M24.6393 49.3368L25.502 49.4401V56.0813H24.7426L24.6707 55.4747C24.3352 55.954 23.8889 56.1937 23.3317 56.1937C22.9243 56.1937 22.5873 56.0903 22.3207 55.8836C22.0571 55.6739 21.8609 55.3834 21.7321 55.0119C21.6033 54.6405 21.5389 54.2091 21.5389 53.7178C21.5389 53.2385 21.6123 52.8132 21.759 52.4417C21.9088 52.0673 22.1215 51.7722 22.3971 51.5565C22.6757 51.3408 23.0112 51.233 23.4036 51.233C23.9129 51.233 24.3247 51.4127 24.6393 51.7722V49.3368ZM23.6283 51.9115C23.2568 51.9115 22.9693 52.0598 22.7656 52.3563C22.5649 52.6529 22.4645 53.1067 22.4645 53.7178C22.4645 54.3259 22.5559 54.7768 22.7386 55.0703C22.9243 55.3639 23.1909 55.5107 23.5384 55.5107C23.787 55.5107 24.0012 55.4403 24.181 55.2995C24.3637 55.1557 24.5165 54.9879 24.6393 54.7962V52.4821C24.5165 52.3024 24.3682 52.1631 24.1944 52.0643C24.0237 51.9624 23.835 51.9115 23.6283 51.9115Z"
			fill="#202020"
		/>
		<path
			d="M31.2455 51.3453L29.5919 56.1083C29.4781 56.4438 29.3313 56.7463 29.1516 57.0159C28.9719 57.2885 28.7397 57.5102 28.4551 57.681C28.1735 57.8517 27.8186 57.9581 27.3902 58L27.2689 57.3305C27.5924 57.2796 27.8485 57.2017 28.0372 57.0968C28.229 56.992 28.3787 56.8557 28.4866 56.6879C28.5974 56.5202 28.6963 56.318 28.7831 56.0813H28.4911L26.86 51.3453H27.7811L29.0707 55.4523L30.3558 51.3453H31.2455Z"
			fill="#202020"
		/>
		<defs>
			<linearGradient
				id="paint0_linear"
				x1="43"
				y1="43"
				x2="12.5"
				y2="43"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#A15DE5" />
				<stop offset="1" stopColor="#1F9DE4" />
			</linearGradient>
			<radialGradient
				id="paint1_radial"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(49 21.5) rotate(106.268) scale(22.3849)"
			>
				<stop stopColor="#FFBFC9" />
				<stop offset="1" stopColor="#FF90A0" />
			</radialGradient>
		</defs>
	</svg>
)
