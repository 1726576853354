import React from 'react'
import { Stack } from '../../generic/components/Stack'
import { IProduct } from '../api-firebase/types'
import { ProductCard } from './ProductCard'

interface IProps {
	products: (IProduct | undefined)[]
}

export const ProductList: React.FC<IProps> = ({ products }) => {
	return (
		<Stack spacing="-1px">
			{products.map((product, i) => (
				<ProductCard key={product ? product.id : i} product={product} />
			))}
		</Stack>
	)
}
