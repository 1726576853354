import React from 'react'
import { classes, keyframes, style } from 'typestyle'

interface IProps extends React.HTMLAttributes<HTMLSpanElement> {
	type?: keyof typeof $types
}

export class TextLoading extends React.PureComponent<IProps> {
	render() {
		const { type = 'dots', className, ...rest } = this.props
		return <span className={classes($container, $types[type], className)} {...rest} />
	}
}

const $container = style({
	display: 'inline-block',
	overflow: 'hidden',
	height: '1.3em',
	marginTop: '-0.3em',
	lineHeight: '1.5em',
	verticalAlign: 'text-bottom',
	$nest: {
		'&:after': { display: 'inline-table', whiteSpace: 'pre', textAlign: 'left' },
	},
})

// Source: https://github.com/maxbeier/text-spinners/blob/master/spinners.css
const $types = {
	dots: style({
		$nest: {
			'&:after': {
				content: `'\\A.\\A..\\A...'`,
				animationName: keyframes({ to: { transform: 'translateY( -6.0em)' } }),
				animationDuration: '2s',
				animationTimingFunction: 'steps(4)',
				animationIterationCount: 'infinite',
			},
		},
	}),
	time: style({
		$nest: {
			'&:after': {
				width: '1.3em',
				content: `'🕐\\A🕑\\A🕒\\A🕓\\A🕔\\A🕕\\A🕖\\A🕗\\A🕘\\A🕙\\A🕚\\A🕛'`,
				animationName: keyframes({ to: { transform: 'translateY(-18.0em)' } }),
				animationDuration: '3s',
				animationTimingFunction: 'steps(12)',
				animationIterationCount: 'infinite',
			},
		},
	}),
}
