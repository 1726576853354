import React from 'react'
import { classes, style } from 'typestyle'
import { tv } from '../../generic/theme'
import { IProduct } from '../api-firebase/types'

interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
	thumbnail: IProduct['thumbnail']
}

export class Thumbnail extends React.PureComponent<IProps> {
	render() {
		const {
			thumbnail: { name },
			className,
			...rest
		} = this.props
		const props: any = {
			...rest,
			width: SIZE,
			height: SIZE,
			src: `https://ik.imagekit.io/sz/tr:w-${SIZE}/${name}`,
			srcSet: DPRs.map(
				(dpr) => `https://ik.imagekit.io/sz/tr:w-${SIZE},dpr-${dpr}/${name} ${dpr}x`
			).join(', '),
			loading: 'lazy',
			className: classes($image, className),
		}
		// eslint-disable-next-line jsx-a11y/alt-text
		return <img {...props} />
	}
}

const SIZE = 80
const DPRs = [2, 3]

const $image = style({
	flex: 'none',
	display: 'block',
	backgroundColor: tv('blue200'),
})
