import { map } from 'rxjs/operators'
import { actionsShell } from '../../generic/actions'
import { routerState$ } from '../../generic/state'
import { createAttachableEpic } from '../../generic/supply/epic-helpers'
import { Page404 } from '../page-404/Page404'
import { PageMain } from '../page-main/PageMain'
import { PageProduct } from '../page-product/PageProduct'
import { PageProducts } from '../page-products/PageProducts'
import { router$, routerRouteToState } from '../router/router'

export const attachEpicShell = createAttachableEpic(() => [
	router$.subscribe(({ route }) => routerState$.set(routerRouteToState(route))),
	routerState$
		.pipe(
			map((state) => {
				switch (state.name) {
					case 'main':
						return PageMain

					case 'products':
						return PageProducts

					case 'products.product':
						return PageProduct

					case 'topics.topic':
						if (ALLOWED_TOPICS.includes(state.params['topics.topic']!.topicSlug)) {
							return PageProducts
						} else {
							return Page404
						}

					default:
						return Page404
				}
			})
		)
		.subscribe(actionsShell.replacePage),
])

const ALLOWED_TOPICS = ['apps', 'wearables', 'devices', 'services', 'sources']
