import { ca } from '../../generic/supply/action-helpers'
import { createAttachableEpic } from '../../generic/supply/epic-helpers'
import { createFig } from '../../generic/supply/fig-helpers'
import { getProductCards } from '../api-firebase/getProductCards'
import { IProduct } from '../api-firebase/types'
import { defaultProductListState, PAGE_PRODUCTS_LIMIT, productListState$ } from './state'

export const loadNextProductBatch = ca()

export const attachEpicPageProducts = createAttachableEpic(() => {
	const figProductList = createFig()
	return [
		loadNextProductBatch.$.subscribe(() => {
			const { offset, topic } = productListState$.get()
			figProductList.run<IProduct[]>({
				task: getProductCards.bind(null, { limit: PAGE_PRODUCTS_LIMIT, offset, topic }),
				attempts: 3,
				onFinish: (products) => {
					if (!(products instanceof Error)) {
						productListState$.set({
							products,
							offset,
							hasMore: true,
						})
					}
				},
			})
		}),
		productListState$.subscribe((stateProductList) => {
			if (stateProductList !== defaultProductListState && stateProductList.offset === 0) {
				loadNextProductBatch()
			}
		}),
	]
})
