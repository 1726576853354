import React from 'react'
import { style } from 'typestyle'
import { heroFont, headerFont } from '../../generic/theme'

export const Page404: React.FC = () => {
	return (
		<div className={$container}>
			<h1 className={$header}>404</h1>
			<p className={$sorry}>Sorry, page not found</p>
			<video autoPlay loop muted playsInline className={$image}>
				<source src="https://media.giphy.com/media/KijmmcKP62qWs/giphy.mp4" type="video/mp4" />
				<img src="https://media.giphy.com/media/KijmmcKP62qWs/giphy.gif" alt="flip flop cat" />
			</video>
		</div>
	)
}

const $container = style({
	flexGrow: 1,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '2rem',
})

const $header = style({
	...heroFont,
})

const $sorry = style({
	...headerFont,
})

const $image = style({
	width: '220px',
	height: '220px',
})
