import React from 'react'
import { canUseDOM } from '../../supply/env-helpers'
import { Portal } from '../Utils/Portal'
import { IModalContext, ModalContext } from './ModalContext'

interface IProps extends IModalContext {
	container?: React.ReactInstance | (() => React.ReactInstance | null)
}

export class Modal extends React.PureComponent<IProps> {
	onEscHandler = (e: KeyboardEvent) => {
		const { onEsc } = this.props
		if (e.key === 'Escape' && onEsc) {
			onEsc(e)
		}
	}
	componentDidMount() {
		const { onEsc } = this.props
		if (canUseDOM && onEsc) {
			document.addEventListener('keydown', this.onEscHandler)
		}
	}
	componentWillUnmount() {
		const { onEsc } = this.props
		if (canUseDOM && onEsc) {
			document.removeEventListener('keydown', this.onEscHandler)
		}
	}
	render() {
		const { container, children, ...context } = this.props
		if (!context.isOpen) {
			return null
		}
		return (
			<ModalContext.Provider value={context}>
				<Portal container={container}>{children}</Portal>
			</ModalContext.Provider>
		)
	}
}
