import React from 'react'
import { classes, style } from 'typestyle'
import { useModalContext } from './ModalContext'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
	maxWidth?: string
	padding?: string
}

export const ModalContent: React.FC<IProps> = ({
	children,
	className,
	maxWidth = '90vw',
	padding = '2rem',
	...rest
}) => {
	const { onOverlayClick } = useModalContext()
	return (
		<div
			className={classes($container, onOverlayClick && $clickable, className)}
			{...rest}
			onClick={onOverlayClick}
		>
			<div className={$wrapper} style={{ maxWidth, padding }}>
				<div className={$content} onClick={stopPropagation}>
					{children}
				</div>
			</div>
		</div>
	)
}

const $container = style({
	position: 'fixed',
	left: 0,
	top: 0,
	width: '100vw',
	height: '100vh',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	overflowY: 'auto',
})

const $clickable = style({
	cursor: 'pointer',
})

const $wrapper = style({
	flex: 'auto',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
})

const $content = style({
	cursor: 'default',
})

const stopPropagation = (e: React.MouseEvent) => e.stopPropagation()
