import createRouter, { constants, Options, State } from 'router5'
import browserPlugin from 'router5-plugin-browser'
import { from, Observable } from 'rxjs'
import { shareReplay } from 'rxjs/operators'
import { routeDeclaration } from './declaration'
import { IRouterState, TKeysOfRouteMap, TRouteParams, TRouteQuery } from './types'

const routes = Object.entries(routeDeclaration).map(([name, { path }]) => ({ name, path }))
const options: Partial<Options> = {
	allowNotFound: true,
	queryParamsMode: 'loose',
}

const router = createRouter(routes, options)
router.usePlugin(browserPlugin())
router.start()

export const router$ = (from(router as any) as Observable<{
	route: State
	previousRoute: State
}>).pipe(shareReplay(1))

export const routerRouteToState = ({ name, path, params }: State): IRouterState => ({
	name: name === constants.UNKNOWN_ROUTE ? null : (name as TKeysOfRouteMap),
	path,
	params: {
		[name]: params,
	},
})

export const routerGetState = () => routerRouteToState(router.getState())

export const routerNavigate = <T extends TKeysOfRouteMap>(
	name: T,
	...a: TRouteParams[T] extends never ? [TRouteQuery?] : [TRouteParams[T] & TRouteQuery]
) => {
	router.navigate(name, a[0] as any)
}

export const routerBuildUrl = router.buildUrl || router.buildPath
