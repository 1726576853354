import { callAPI, parseFields, fieldsToSelect } from './utils'
import { IFireStoreDocument, IProduct } from './types'

export const getProductCards = async ({
	topic,
	limit,
	offset,
}: {
	topic?: string
	limit: number
	offset?: number
}) => {
	const structuredQuery: any = { ...PRODUCT_CARD_QUERY, limit, offset }
	if (topic) {
		structuredQuery.where = {
			compositeFilter: {
				op: 'AND',
				filters: [
					{
						fieldFilter: {
							field: {
								fieldPath: 'isPublished',
							},
							op: 'EQUAL',
							value: {
								booleanValue: true,
							},
						},
					},
					{
						fieldFilter: {
							field: {
								fieldPath: 'topics',
							},
							op: 'ARRAY_CONTAINS',
							value: {
								stringValue: topic,
							},
						},
					},
				],
			},
		}
	} else {
		structuredQuery.where = {
			fieldFilter: {
				field: {
					fieldPath: 'isPublished',
				},
				op: 'EQUAL',
				value: {
					booleanValue: true,
				},
			},
		}
	}
	const res = await callAPI<{ document: IFireStoreDocument<IProduct> }[]>(':runQuery', {
		method: 'POST',
		headers: {
			'content-type': 'application/json; charset=utf-8',
		},
		body: JSON.stringify({ structuredQuery }),
	})
	if (offset) {
		res.shift()
	}
	return res.map(({ document }) => {
		return {
			...parseFields(document.fields),
			created: new Date(document.createTime),
		}
	}) as IProduct[]
}

const PRODUCT_CARD_QUERY = {
	select: {
		fields: fieldsToSelect(['id', 'name', 'thumbnail', 'topics', 'tagline']),
	},
	from: [
		{
			collectionId: 'products',
		},
	],
	orderBy: {
		field: {
			fieldPath: 'created',
		},
		direction: 'DESCENDING',
	},
}
