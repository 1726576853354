import React from 'react'
import { Helmet } from 'react-helmet'
import { IProduct } from '../api-firebase/types'

export const SEO: React.FC<{ product: IProduct }> = ({
	product: { name, tagline, gallery, thumbnail },
}) => {
	let imageURL!: string
	const image = gallery && gallery.find(({ tags }) => tags.includes('og:image'))
	if (image) {
		imageURL = `https://ik.imagekit.io/sz/tr:w-1200,h-630/${image.name}`
	} else if (thumbnail) {
		imageURL = `https://ik.imagekit.io/sz/tr:w-800/${thumbnail.name}`
	}
	const title = `${name} — Body Link`
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={tagline} />

			{/* <!-- Open Graph / Facebook --> */}
			<meta property="og:type" content="product" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={tagline} />
			<meta property="og:image" content={imageURL} />

			{/* <!-- Twitter --> */}
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={tagline} />
			<meta name="twitter:image" content={imageURL} />
		</Helmet>
	)
}
