import React from 'react'
import { style } from 'typestyle'
import { Box } from '../../generic/components/Box'
import { ContentLoading } from '../../generic/components/ContentLoading'
import { ProductLink } from '../../generic/components/ProductLink'
import { Stack } from '../../generic/components/Stack'
import { YouTubePlayer } from '../../generic/components/YouTubePlayer'
import { stringToSlug, urlToAnchor } from '../../generic/supply/formatters'
import { horizontalSpaced, mediaGrad } from '../../generic/supply/style-helpers'
import {
	applyVariables,
	linkFontFancy,
	mediumFont,
	mediumFontBold,
	smallFont,
	smallFontBold,
	tv,
} from '../../generic/theme'
import { IProduct } from '../api-firebase/types'
import { Link } from '../router/Link'
import { GalleryImage } from './GalleryImage'
import GalleryLightbox, { openLightbox } from './GalleryLightbox'
import { SEO } from './SEO'

export const ProductBody: React.FC<{ product?: IProduct }> = ({ product }) => {
	return <div className={$container}>{product ? <Loaded product={product} /> : <Loading />}</div>
}

const Loading: React.FC = () => (
	<Box color="white" isBordered className={$main}>
		<ContentLoading
			style={{ width: '100%', maxWidth: 350 }}
			title="Product details is loading"
			height={350}
			width={350}
		>
			<rect x="0" y="0" rx="4" ry="4" width="110" height="40" />
			<rect x="120" y="0" rx="4" ry="4" width="120" height="40" />
			<rect x="0" y="72" rx="0" ry="0" width="350" height="200" />
			<rect x="0" y="292" rx="0" ry="0" width="350" height="14" />
			<rect x="0" y="314" rx="0" ry="0" width="350" height="14" />
			<rect x="0" y="336" rx="0" ry="0" width="130" height="14" />
		</ContentLoading>
	</Box>
)

const Loaded: React.FC<{ product: IProduct }> = ({ product }) => {
	const {
		id,
		productLinks,
		otherLinks,
		videoID,
		description,
		status,
		authors,
		languages,
		topics,
		tags,
		updated,
		gallery,
	} = product
	return (
		<Box color="white" isBordered className={$main}>
			<SEO product={product} />
			{Boolean(productLinks.length) && (
				<Stack spacing={1} isInline isWrap>
					{productLinks.map((link, index) => (
						<ProductLink key={index} link={link} />
					))}
				</Stack>
			)}
			{videoID && <YouTubePlayer videoID={videoID} className={$video} />}
			<div className={$description}>{description}</div>
			<h2 className={$header}>Information</h2>
			<ul className={$table}>
				{Boolean(otherLinks.length) && (
					<li className={$tableRow}>
						<div className={$tableHeader}>Links</div>
						<div className={$tableContent}>
							<Stack spacing={1} isInline isWrap>
								{otherLinks.map(({ url, name }, i) => (
									<SLink url={url} key={i}>
										{name || urlToAnchor(url)}
									</SLink>
								))}
							</Stack>
						</div>
					</li>
				)}
				<li className={$tableRow}>
					<div className={$tableHeader}>Status</div>
					<div className={$tableContent}>{status}</div>
				</li>
				{Boolean(authors.length) && (
					<li className={$tableRow}>
						<div className={$tableHeader}>Authors</div>
						<div className={$tableContent}>
							<Stack spacing={1} isInline isWrap>
								{authors.map(({ url, name }, i) =>
									url ? (
										<SLink url={url} key={i}>
											{name}
										</SLink>
									) : (
										<span key={i}>{name}</span>
									)
								)}
							</Stack>
						</div>
					</li>
				)}
				{Boolean(languages.length) && (
					<li className={$tableRow}>
						<div className={$tableHeader}>Languages</div>
						<div className={$tableContent}>
							<Stack spacing={1} isInline isWrap>
								{languages.map((languageCode, i) => (
									<span key={i}>{languageCode}</span>
								))}
							</Stack>
						</div>
					</li>
				)}
				{Boolean(topics.length) && (
					<li className={$tableRow}>
						<div className={$tableHeader}>Topics</div>
						<div className={$tableContent}>
							<Stack spacing={1} isInline isWrap>
								{topics.map((topic) => (
									<Link
										key={topic}
										name="topics.topic"
										props={{ topicSlug: stringToSlug(topic) }}
										className={$sLink}
									>
										{topic}
									</Link>
								))}
							</Stack>
						</div>
					</li>
				)}
				{Boolean(tags.length) && (
					<li className={$tableRow}>
						<div className={$tableHeader}>Tags</div>
						<div className={$tableContent}>
							<Stack spacing={1} isInline isWrap>
								{tags.map((tag, i) => (
									<span key={i}>{tag}</span>
								))}
							</Stack>
						</div>
					</li>
				)}
				<li className={$tableRow}>
					<div className={$tableHeader}>Updated</div>
					<div className={$tableContent}>
						{updated.toLocaleDateString('en-US', {
							year: 'numeric',
							month: 'long',
							day: 'numeric',
						})}
					</div>
				</li>
			</ul>
			{Boolean(gallery.length) && (
				<>
					<h2 className={$header}>Gallery</h2>
					<Stack spacing={6}>
						{gallery.map((image, index) => (
							<GalleryImage key={index} image={image} onClick={openLightbox._(index)} />
						))}
					</Stack>
					<GalleryLightbox productID={id} />
				</>
			)}
		</Box>
	)
}

const SLink: React.FC<{ url: string }> = ({ url, children }) => (
	<a href={url} target="_blank" rel="noopener noreferrer" className={$sLink}>
		{children}
	</a>
)

const $container = style(
	applyVariables({
		'space-card': '20px',
		'space-between': '4rem',
	}),
	{
		position: 'relative',
	},
	mediaGrad(
		'mobileM',
		applyVariables({
			'space-card': '2rem',
		})
	),
	mediaGrad(
		'mdPlus',
		applyVariables({
			'space-between': '6rem',
		})
	)
)

const $main = style({
	padding: 'var(--space-card)',
})

const $video = style({
	marginTop: 'var(--space-between)',
})

const $description = style(mediumFont, {
	whiteSpace: 'pre-wrap',
	marginTop: 'calc(var(--space-between) / 2)',
	color: tv('dark500'),
})

const $header = style(mediumFontBold, {
	marginTop: 'var(--space-between)',
	color: tv('dark800'),
})

const $table = style({
	margin: 0,
	padding: 0,
})

const $tableRow = style(
	horizontalSpaced(4),
	{
		display: 'flex',
		padding: '.7rem 0',
	},
	mediaGrad('mobileS', horizontalSpaced(2))
)

const $tableHeader = style(
	smallFontBold,
	{
		flex: 'none',
		width: '80px',
		textAlign: 'right',
	},
	mediaGrad('md', {
		width: '100px',
	}),
	mediaGrad('lgPlus', {
		width: '120px',
	})
)
const $tableContent = style(smallFont)

const $sLink = style(smallFont, linkFontFancy({ colorLink: tv('link500') }))
