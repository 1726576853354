import React from 'react'
import { horizontalSpaced, verticalSpaced, gridSpaced } from '../supply/style-helpers'
import { types, style, classes } from 'typestyle'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
	isInline?: boolean
	isWrap?: boolean
	isCentered?: boolean
	spacing: number | string
}

export const Stack: React.FC<IProps> = ({
	isInline = false,
	isWrap = false,
	isCentered = false,
	spacing,
	children,
	className,
	...rest
}) => {
	const baseStyle: types.NestedCSSProperties = {
		display: 'flex',
	}
	const spaced = isWrap ? gridSpaced : isInline ? horizontalSpaced : verticalSpaced
	if (isWrap) {
		baseStyle.flexWrap = 'wrap'
	}
	if (!isInline) {
		baseStyle.flexDirection = 'column'
	}
	if (isCentered) {
		baseStyle.alignItems = 'center'
	}
	return (
		<div className={classes(style(spaced(spacing), baseStyle), className)} {...rest}>
			{children}
		</div>
	)
}
