import React from 'react'
import { graduations$ } from '../../state-env'
import { TGraduationName } from '../../theme'
import { ReactiveBind } from '../Reactive/Bind'

interface IProps {
	children: (graduations: { [key in TGraduationName]: boolean }) => React.ReactNode
}

export const Graduations: React.FC<IProps> = ({ children }) => (
	<ReactiveBind $={graduations$}>{children}</ReactiveBind>
)
