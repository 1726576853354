import React from 'react'
import ContentLoader from 'react-content-loader'
import { lightTheme } from '../theme'

interface IProps {
	title: string
	height: number
	width: number
	className?: string
	style?: React.CSSProperties
}

export class ContentLoading extends React.PureComponent<IProps> {
	render() {
		const { title, width, height, children, ...rest } = this.props
		return (
			<ContentLoader
				{...rest}
				ariaLabel={title}
				height={height}
				width={width}
				speed={2}
				primaryColor={lightTheme.blue200}
				secondaryColor={lightTheme.pink400}
				secondaryOpacity={0.5}
			>
				{children}
			</ContentLoader>
		)
	}
}
