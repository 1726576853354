import React from 'react'
import { Helmet } from 'react-helmet'

export const SEO: React.FC = () => {
	const title = process.env.REACT_APP_OG_SITE_NAME
	const description = process.env.REACT_APP_OG_DESCRIPTION
	const imageURL = `${process.env.PUBLIC_URL}/og-image.jpg`
	const ogTitle = process.env.REACT_APP_OG_TITLE
	const social = `@${process.env.REACT_APP_SOCIAL}`
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />

			{/* <!-- Open Graph / Facebook --> */}
			<meta property="fb:app_id" content={process.env.REACT_APP_FB_APP_ID} />
			<meta property="og:type" content="article" />
			<meta property="og:locale" content="en_US" />
			<meta property="og:url" content={String(document.location)} />
			<meta property="og:site_name" content={title} />
			<meta property="og:title" content={ogTitle} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={imageURL} />

			{/* <!-- Twitter --> */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content={social} />
			<meta name="twitter:creator" content={social} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={imageURL} />
		</Helmet>
	)
}
