import React from 'react'
import { style } from 'typestyle'
import { graduationToMediaCondition } from '../../generic/supply/style-helpers'
import { smallFont, tv, smallFontBold } from '../../generic/theme'
import { IProduct } from '../api-firebase/types'

interface IProps {
	onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
	image: IProduct['gallery'][0]
}

export class GalleryImage extends React.PureComponent<IProps> {
	render() {
		const {
			onClick,
			image: { name, height, width },
		} = this.props
		const figCaption = composeCaption(this.props.image)
		const origin = `https://ik.imagekit.io/sz/${name}`
		const props: any = {
			width,
			height,
			alt: figCaption,
			src: origin,
			srcSet: WIDTHS.filter((w) => w < width)
				.map((w) => `https://ik.imagekit.io/sz/tr:w-${w}/${name} ${w}w`)
				.concat(`${origin} ${width}w`)
				.join(', '),
			sizes: SIZES,
			loading: 'lazy',
			className: $image,
		}
		return (
			<figure className={$container}>
				<a
					href={origin}
					className={$cover}
					style={{ paddingBottom: `${100 / (width / height)}%` }}
					onClick={(e) => {
						e.preventDefault()
						onClick(e)
					}}
				>
					{/* eslint-disable-next-line jsx-a11y/alt-text */}
					<img {...props} />
				</a>
				{figCaption && <figcaption className={$caption}>{figCaption}</figcaption>}
			</figure>
		)
	}
}

const DPRs = [1, 2, 3]
const WIDTHS = [350, 638].reduce<number[]>((acc, bp) => acc.concat(DPRs.map((dpr) => bp * dpr)), [])
const SIZES = [`${graduationToMediaCondition('mobileL')} 350px`, '680px'].join(', ')
const HIDDEN_TAGS = ['og:image']

export const composeCaption = (image: IProduct['gallery'][0]) => {
	const tags = image.tags.filter((tag) => !HIDDEN_TAGS.includes(tag))
	return image.caption ? image.caption : tags.length ? tags.join(', ') : null
}

const $container = style({
	display: 'block',
	margin: 0,
})

const $cover = style({
	position: 'relative',
	display: 'flex',
	justifyContent: 'center',
	height: 0,
	overflow: 'hidden',
})

const $image = style(smallFont, {
	position: 'absolute',
	display: 'block',
	width: 'auto',
	height: '100%',
	backgroundColor: tv('blue200'),
})

const $caption = style(smallFontBold, {
	display: 'block',
	marginTop: '1rem',
	color: tv('dark500'),
	textAlign: 'center',
})
