import { IFireStoreDocument, IFireStoreError, IProduct } from './types'
import { callAPI, parseFields } from './utils'

export const getProduct = async (id: string) => {
	const res = await callAPI<IFireStoreDocument<IProduct> | IFireStoreError>(`/products/${id}`)
	if ('error' in res) {
		return null
	} else {
		return parseFields(res.fields) as IProduct
	}
}
