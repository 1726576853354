import React from 'react'
import { style } from 'typestyle'
import { ReactiveBind } from '../../generic/components/Reactive/Bind'
import { Stack } from '../../generic/components/Stack'
import { mediaGrad } from '../../generic/supply/style-helpers'
import { Page404 } from '../page-404/Page404'
import { ProductBody } from '../product/ProductBody'
import { ProductHead } from '../product/ProductHead'
import { attachEpicPageProduct } from './epic'
import { isFound$, productState$ } from './state'
import { ShareButtons } from './ShareButtons'
import { IProduct } from '../api-firebase/types'

export const PageProduct = attachEpicPageProduct(() => {
	return (
		<ReactiveBind $={isFound$}>
			{(isFound) => {
				if (isFound) {
					return (
						<ReactiveBind $={productState$.view('product')}>
							{(product) => (
								<div className={$container}>
									<div className={$content}>
										<Stack spacing="-1px">
											<ProductHead product={product} />
											<ProductBody
												product={product && product.description ? product : undefined}
											/>
											{Boolean(product && product.description) && (
												<ShareButtons product={product as IProduct} />
											)}
										</Stack>
									</div>
								</div>
							)}
						</ReactiveBind>
					)
				}
				return <Page404 />
			}}
		</ReactiveBind>
	)
})

const $container = style(
	{
		padding: '3rem 2rem',
		flex: 'none',
		overflowX: 'hidden',
	},
	mediaGrad('mdPlus', {
		padding: '5rem 6rem 6rem',
	})
)

const $content = style({
	maxWidth: '680px',
})
