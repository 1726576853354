import { LiftWrapper } from '@grammarly/focal/dist/src/react'
import React from 'react'
import { Observable } from 'rxjs'

export class ReactiveBind<T> extends React.Component<{
	$: Observable<T>
	children?: (value: T) => React.ReactNode
}> {
	shouldComponentUpdate() {
		return false
	}
	render() {
		const { $: value, children: projection } = this.props
		return (
			<LiftWrapper
				props={{ value }}
				component={(props) => (projection ? projection(props.value) : (props.value as any))}
			/>
		)
	}
}
