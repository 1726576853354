import { px } from 'csx'
import React from 'react'
import { classes, style } from 'typestyle'
import { TIconName } from '../icons'
import { applyVariables, mediumFontBold, smallFontBold, tv } from '../theme'
import { Icon } from './Icon'

interface IButtonProps {
	color?: keyof typeof $colors
	ghostColor?: string
	size?: keyof typeof $sizes
	leftIcon?: TIconName
}

export type TButtonProps = IButtonProps & React.HTMLAttributes<HTMLButtonElement>
export type TButtonLinkProps = IButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>

const createButtonLike = (element: 'button' | 'a') => ({
	color = 'white',
	size = 'md',
	ghostColor,
	leftIcon,
	children,
	className,
	...rest
}: TButtonProps | TButtonLinkProps) => {
	const $color = ghostColor
		? style(
				applyVariables({
					'color-btn': ghostColor,
					'color-btn-bg': 'transparent',
					'color-btn-border': ghostColor,
				})
		  )
		: $colors[color]
	const $size = $sizes[size]
	return React.createElement(
		element,
		{
			...rest,
			className: classes($color, $size, $container, className),
		},
		<>
			{leftIcon && (
				<Icon
					icon={leftIcon}
					size={{ sm: '18px', md: '24px' }[size]}
					style={{ marginRight: children ? '.3em' : undefined }}
				/>
			)}
			{children}
		</>
	)
}

export const Button: React.FC<TButtonProps> = createButtonLike('button')
export const ButtonLink: React.FC<TButtonLinkProps> = createButtonLike('a')

const $container = style({
	position: 'relative',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: 'var(--color-btn)',
	backgroundColor: 'var(--color-btn-bg)',
	border: '1px solid var(--color-btn-border)',
	borderRadius: px(4),
	padding: '0 var(--size-btn-p)',
	height: 'var(--size-btn-h)',
	minWidth: 'var(--size-btn-h)',
	whiteSpace: 'nowrap',
	textDecoration: 'none',
	outline: 'none',
	userSelect: 'none',
	cursor: 'pointer',
})

const $colors = {
	pink: style(
		applyVariables({
			'color-btn': tv('white'),
			'color-btn-bg': tv('pink500'),
			'color-btn-border': tv('pink700'),
		})
	),
	blue: style(
		applyVariables({
			'color-btn': tv('white'),
			'color-btn-bg': tv('blue500'),
			'color-btn-border': tv('blue700'),
		})
	),
	white: style(
		applyVariables({
			'color-btn': tv('link500'),
			'color-btn-bg': tv('white'),
			'color-btn-border': tv('link500'),
		})
	),
}

const $sizes = {
	sm: style({
		...applyVariables({
			'size-btn-h': '28px',
			'size-btn-p': '7px',
		}),
		...smallFontBold,
	}),
	md: style({
		...applyVariables({
			'size-btn-h': '40px',
			'size-btn-p': '11px',
		}),
		...mediumFontBold,
	}),
}
