import React from 'react'
import { style } from 'typestyle'
import { Box } from '../../generic/components/Box'
import { Stack } from '../../generic/components/Stack'
import { Graduations } from '../../generic/components/Utils/Graduations'
import { stringToSlug } from '../../generic/supply/formatters'
import { linkFontFancy, mediumFont, tv } from '../../generic/theme'
import { Link } from '../router/Link'
import { Logo } from './Logo'
import { MenuOverlay } from './MenuOverlay'
import { menuItems } from './state'
import { ButtonLink } from '../../generic/components/Button'

export const Header: React.FC = () => {
	return (
		<Box shadow="header" className={$container}>
			<Link name="main" className={$logotype}>
				<Logo />
			</Link>
			<Graduations>
				{({ sm, lgPlus }) => {
					if (sm) {
						return <MenuOverlay />
					}
					return (
						<Stack spacing={3} isInline isCentered className={$navigation}>
							<Stack spacing={3} isInline>
								{menuItems.map(({ text }) => (
									<Link
										key={text}
										name="topics.topic"
										props={{ topicSlug: stringToSlug(text) }}
										className={$navLink}
									>
										{text}
									</Link>
								))}
							</Stack>
							<div className={$left}>
								<ButtonLink
									href="https://forms.gle/d9ChrKFJvVqXbHLq7"
									target="_blank"
									leftIcon="plus"
									size="sm"
								>
									{lgPlus ? 'Submit a product' : 'Product'}
								</ButtonLink>
							</div>
						</Stack>
					)
				}}
			</Graduations>
		</Box>
	)
}

const $container = style({
	display: 'flex',
	alignItems: 'center',
	height: 70,
})

const $logotype = style({
	padding: '0 2rem',
})

const $navigation = style({
	flexGrow: 1,
	maxWidth: '900px',
	padding: '0 2rem',
})

const $navLink = style({
	...mediumFont,
	...linkFontFancy({ colorLink: tv('dark500') }),
})

const $left = style({
	marginLeft: 'auto',
})
