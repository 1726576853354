import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { style } from 'typestyle'
import { ReactiveBind } from '../../generic/components/Reactive/Bind'
import { Stack } from '../../generic/components/Stack'
import { mediaGrad } from '../../generic/supply/style-helpers'
import { headerFont, smallFont, tv } from '../../generic/theme'
import { ProductList } from '../product/ProductList'
import { attachEpicPageProducts, loadNextProductBatch } from './epic'
import { productListState$, title$ } from './state'

export const PageProducts = attachEpicPageProducts(() => {
	return (
		<div className={$container}>
			<div className={$content}>
				<div className={$header}>
					<Stack spacing={2} isInline isCentered>
						<h2 className={$title}>
							<ReactiveBind $={title$} />
						</h2>
						<span className={$sub}>chronological order</span>
					</Stack>
				</div>
				<div className={$productList}>
					<ReactiveBind $={productListState$}>
						{({ products, hasMore }) => (
							<InfiniteScroll
								dataLength={products.length}
								next={loadNextProductBatch}
								hasMore={hasMore}
								loader={null}
								scrollableTarget="root"
							>
								<ProductList products={products} />
							</InfiniteScroll>
						)}
					</ReactiveBind>
				</div>
			</div>
		</div>
	)
})

const $container = style(
	{
		padding: '1rem 2rem 2rem',
		flex: 'none',
		overflowX: 'hidden',
	},
	mediaGrad('mdPlus', {
		padding: '5rem 6rem 6rem',
	})
)

const $content = style(
	{
		marginTop: '6rem',
		maxWidth: '470px',
	},
	mediaGrad('sm', {
		marginTop: '4rem',
	})
)

const $header = style({
	display: 'flex',
	alignItems: 'center',
})

const $title = style(headerFont, {
	margin: 0,
	color: tv('dark500'),
})

const $sub = style(smallFont, {
	color: tv('dark400'),
})

const $productList = style({
	marginTop: '2rem',
})
