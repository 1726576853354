import { useMemo, useLayoutEffect, useEffect } from 'react'

export function setRef<T>(
	ref: React.RefObject<T> | ((instance: T | null) => void) | null | undefined,
	value: T | null
): void {
	if (typeof ref === 'function') {
		ref(value)
	} else if (ref) {
		;(ref as any).current = value
	}
}

export function useForkRef<T>(refA: React.Ref<T>, refB: React.Ref<T>): React.Ref<T> {
	return useMemo(() => {
		if (refA == null && refB == null) {
			return null
		}
		return (refValue) => {
			setRef(refA, refValue)
			setRef(refB, refValue)
		}
	}, [refA, refB])
}

export const useEnhancedEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect
