export const routeDeclaration = {
	main: {
		path: '/',
		params: [],
	},
	products: {
		path: '/products',
		params: [],
	},
	'products.product': {
		path: '/:productSlug',
		params: ['productSlug'],
	},
	topics: {
		path: '/topics',
		params: [],
	},
	'topics.topic': {
		path: '/:topicSlug',
		params: ['topicSlug'],
	},
} as const
