import React from 'react'
import { style } from 'typestyle'
import { Box } from '../../generic/components/Box'
import { Button, ButtonLink } from '../../generic/components/Button'
import { Modal } from '../../generic/components/Modal/Modal'
import { ModalContent } from '../../generic/components/Modal/ModalContent'
import { ModalOverlay } from '../../generic/components/Modal/ModalOverlay'
import { stringToSlug } from '../../generic/supply/formatters'
import { linkFont, mediumFont, tv, shadowStairs } from '../../generic/theme'
import { Link } from '../router/Link'
import { menuItems } from './state'

export const MenuOverlay: React.FC = () => {
	const [isOpen, setIsOpen] = React.useState(false)

	const open = () => setIsOpen(true)
	const close = () => setIsOpen(false)

	return (
		<>
			<Button
				leftIcon="menu"
				className={$menuLink}
				onClick={open}
				style={{ zIndex: isOpen ? undefined : 1 }}
			>
				Menu
			</Button>
			<Modal isOpen={isOpen} onEsc={close}>
				<ModalOverlay />
				<ModalContent>
					<Box isRounded shadow="modal">
						<div className={$header}>
							<ButtonLink
								href="https://forms.gle/d9ChrKFJvVqXbHLq7"
								target="_blank"
								leftIcon="plus"
								size="sm"
							>
								Submit a product
							</ButtonLink>
						</div>
						<div>
							{menuItems.map(({ text }) => (
								<Link
									key={text}
									name="topics.topic"
									props={{ topicSlug: stringToSlug(text) }}
									className={$navLink}
									onClick={close}
								>
									{text}
								</Link>
							))}
						</div>
						<div className={$footer}>
							<Button leftIcon="close" onClick={close} />
						</div>
					</Box>
				</ModalContent>
			</Modal>
		</>
	)
}

const $menuLink = style(shadowStairs, {
	position: 'fixed',
	right: '2rem',
})

const $navLink = style({
	...mediumFont,
	...linkFont({ colorLink: tv('dark500') }),
	display: 'block',
	padding: '2rem',
	textAlign: 'center',
})

const $header = style({
	display: 'flex',
	padding: '2rem',
	justifyContent: 'center',
})

const $footer = style({
	display: 'flex',
	padding: '2rem',
	$nest: {
		'&>button': {
			flexGrow: 1,
		},
	},
})
