import React from 'react'
import { classes, style } from 'typestyle'
import { mapToQuery } from '../supply/formatters'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
	videoID: string
	isAutoPlayed?: boolean
}

export class YouTubePlayer extends React.PureComponent<IProps> {
	render() {
		const { videoID, isAutoPlayed = true, className, ...rest } = this.props
		const au = Number(isAutoPlayed)
		return (
			<div className={classes($container, className)} {...rest}>
				<iframe
					{...iframeProps}
					title="YouTube video player"
					src={`https://www.youtube.com/embed/${videoID}${query}&autoplay=${au}&mute=${au}`}
					className={$player}
				/>
			</div>
		)
	}
}

const iframeProps = {
	width: '2046',
	height: '952',
	frameBorder: '0',
	allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
	allowFullScreen: true,
	loading: 'lazy',
}

const query = mapToQuery({
	// not show a YouTube logo
	modestbranding: 1,

	// causes video annotations to not be shown
	iv_load_policy: 3,

	// causes closed captions to be shown
	cc_load_policy: 1,

	enablejsapi: 1,
	origin: window.location.origin,
	widget_referrer: window.location.origin,
})

const $container = style({
	position: 'relative',
	paddingBottom: '56.25%',
	height: 0,
})

const $player = style({ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' })
