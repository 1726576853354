import { format } from 'timeago.js'

export const stringToSlug = (str: string) => str.replace(/\s+/g, '-').toLowerCase()

export const slugToTitle = (slug: string) =>
	slug.replace('-', ' ').replace(/^./, (str) => str.toUpperCase())

export const mapToQuery = (map?: Record<string, string | number>) => {
	let q: string = ''
	if (map) {
		q = Object.entries(map)
			.map((v) => v.join('='))
			.join('&')
	}
	return q ? `?${q}` : q
}

export const timeAgo = (date: Date) => format(date, 'en_US')

export const urlToAnchor = (url: string) => {
	try {
		url = new URL(url).hostname
		let name: string | undefined
		for (const key in DOMAINS) {
			if (url.includes(key)) {
				name = DOMAINS[key as keyof typeof DOMAINS]
				break
			}
		}
		url = name || url.replace('www.', '')
	} catch (error) {
		url = url.substring(1, 14).trim()
	}
	return url
}

export const ANCHOR = {
	appStore: 'App Store',
	googlePlay: 'Google Play',
	twitter: 'Twitter',
	fb: 'Facebook',
	github: 'GitHub',
	medium: 'Medium',
	instagram: 'Instagram',
	li: 'LinkedIn',
	youtube: 'YouTube',
	pinterest: 'Pinterest',
	reddit: 'reddit',
	crunchbase: 'Crunchbase',
	angelList: 'AngelList',
	wikipedia: 'Wiki',
}

const DOMAINS = {
	'facebook.com': ANCHOR.fb,
	'fb.com': ANCHOR.fb,
	'twitter.com': ANCHOR.twitter,
	'instagram.com': ANCHOR.instagram,
	'linkedin.com': ANCHOR.li,
	'github.com': ANCHOR.github,
	'youtube.com': ANCHOR.youtube,
	'apps.apple.com': ANCHOR.appStore,
	'play.google.com': ANCHOR.googlePlay,
	'medium.com': ANCHOR.medium,
	'pinterest.com': ANCHOR.pinterest,
	'reddit.com': ANCHOR.reddit,
	'crunchbase.com': ANCHOR.crunchbase,
	'angel.co': ANCHOR.angelList,
	'wikipedia.org': ANCHOR.wikipedia,
}
