import { Lens } from '@grammarly/focal'
import { appState$, IState } from '../../generic/state'
import { stringToSlug, slugToTitle } from '../../generic/supply/formatters'
import { IProduct } from '../api-firebase/types'

interface IProductListState {
	products: IProduct[]
	hasMore: boolean
	offset: number
	topic?: string
}

export const productListState$ = appState$.lens(
	Lens.create<IState, IProductListState>(
		({ router, products }) => {
			if (isPageOpened(router)) {
				let topic: undefined | string
				const pageSlug = getPageSlug(router)
				const { hasMore, offset } = products.pagination[pageSlug] || defaultPagination
				let nextProducts = Object.values(products.cache)
				if (pageSlug !== defaultPageSlug) {
					nextProducts = nextProducts.filter(({ topics }) =>
						topics.map(stringToSlug).includes(pageSlug)
					)
					topic = slugToTitle(pageSlug)
				}
				nextProducts = nextProducts.sort((a, b) => Number(b.created) - Number(a.created))
				return {
					products: hasMore ? nextProducts.concat(Array.from({ length: 3 })) : nextProducts,
					hasMore,
					offset,
					topic,
				}
			} else {
				return defaultProductListState
			}
		},
		(state, appState) => {
			const pageSlug = getPageSlug(appState.router)
			const stateProducts = appState.products
			const products = state.products
			const productsLength = products.length
			return {
				...appState,
				products: {
					...stateProducts,
					cache: products.reduce<IState['products']['cache']>(
						(cache, product) => {
							cache[product.id] = product
							return cache
						},
						{ ...stateProducts.cache }
					),
					pagination: {
						...stateProducts.pagination,
						[pageSlug]: {
							hasMore: PAGE_PRODUCTS_LIMIT === productsLength,
							offset: state.offset + productsLength,
						},
					},
				},
			}
		}
	)
)

export const title$ = productListState$.view('topic').view(Lens.withDefault('Product List'))

const isPageOpened = ({ name }: IState['router']) => name === 'products' || name === 'topics.topic'

const getPageSlug = (router: IState['router']) =>
	router.params['topics.topic'] ? router.params['topics.topic'].topicSlug : defaultPageSlug

const defaultPageSlug = 'products'
const defaultPagination = { hasMore: true, offset: 0 }
export const defaultProductListState: IProductListState = { products: [], ...defaultPagination }

export const PAGE_PRODUCTS_LIMIT = 20
