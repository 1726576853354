import React from 'react'
import { ButtonLink, TButtonLinkProps } from './Button'
import { urlToAnchor, ANCHOR } from '../supply/formatters'

interface IProps extends TButtonLinkProps {
	link: { url: string; name?: string }
}

export const ProductLink: React.FC<IProps> = ({ link: { name, url }, ...rest }) => {
	const anchor = name || urlToAnchor(url)
	const icon = ICONS[anchor] || 'website'
	return (
		<ButtonLink
			color="blue"
			leftIcon={icon}
			href={url}
			target="_blank"
			rel="noopener noreferrer"
			{...rest}
		>
			{anchor}
		</ButtonLink>
	)
}

const ICONS = {
	[ANCHOR.googlePlay]: 'googlePlay',
	[ANCHOR.appStore]: 'appStore',
} as const
