import React from 'react'
import { ButtonLink, TButtonLinkProps } from '../../generic/components/Button'
import { routerBuildUrl, routerNavigate } from './router'
import { TKeysOfRouteMap, TRouteParams, TRouteQuery } from './types'

type TProps<T extends TKeysOfRouteMap> = {
	name: T
	isStatic?: boolean
	target?: string
} & (TRouteParams[T] extends never
	? {
			props?: TRouteQuery
	  }
	: {
			props: TRouteParams[T] & TRouteQuery
	  })

const createLinkLike = (element: 'a' | typeof ButtonLink) => ({
	name,
	props,
	isStatic = false,
	children,
	onClick,
	target,
	...restProps
}: TProps<any> & React.HTMLAttributes<HTMLAnchorElement>) => {
	const handleClick = isStatic
		? onClick
		: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
				if (onClick) {
					onClick(e)
					if (e.defaultPrevented) {
						return
					}
				}
				const comboKey = e.metaKey || e.altKey || e.ctrlKey || e.shiftKey
				if (e.button === 0 && !comboKey && target !== '_blank') {
					e.preventDefault()
					routerNavigate(name, props as any)
				}
		  }
	return React.createElement(
		element,
		{
			href: routerBuildUrl(name, props as any),
			onClick: handleClick,
			...restProps,
		} as any,
		children
	)
}

export const LinkButton: <T extends TKeysOfRouteMap>(
	props: TProps<T> & TButtonLinkProps
) => React.ReactElement = createLinkLike(ButtonLink)

export const Link: <T extends TKeysOfRouteMap>(
	props: TProps<T> & React.AnchorHTMLAttributes<HTMLAnchorElement>
) => React.ReactElement = createLinkLike('a')

/* ;() => {
	;<Link name="products.product" props={{ productSlug: '34' }} />
	;<Link name="products.product" props={{ productSlug: '34', left: 44 }} />
	;<Link name="main" props={{ left: 34 }} />
	;<Link name="main" onClick={() => {}} />

	// Should be error
	;<Link name="products.product" props={{ g: 4 }} />
	;<Link name="products.product" />
	;<Link name="left" />
} */
